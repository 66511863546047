<template>
  <div class="page-container">
    <div class="banner">
      &nbsp;
    </div>
    <div
      v-if="loading"
      class="container"
    >
      <div class="title">
        <skeleton-loader
          type="rect"
          :width="500"
          :height="45"
          rounded
          animation="wave"
        />
      </div>
      <div class="time">
        <skeleton-loader
          type="rect"
          :width="200"
          rounded
          :height="24"
          animation="wave"
        />
      </div>

      <div
        class="content"
        style="height: 700px;"
      >
        <skeleton-loader
          type="rect"
          width="100%"
          rounded
          height="100%"
          animation="wave"
        />
      </div>
    </div>
    <div
      v-if="event"
      class="container"
    >
      <div class="title">
        {{ event.title }}
      </div>
      <div class="time">
        {{ event.time }}
      </div>
      <div
        class="content"
        v-html="$marked(event.content)"
      />
    </div>
    <div
      v-if="event&&event.type === 'insights'"
      class="text-center mt-4"
    >
      <a
        class="download"
        @click="showFeedback"
      >
        <img
          src="https://cdn.yafocapital.com/strapi_Screen_Shot_2022_06_16_at_4_58_39_PM_e159745c90.png"
          alt=""
        >
      </a>
    </div>
    <b-modal
      ref="feedback-modal"
      centered
      hide-footer
    >
      <template #modal-title>
        Download report
      </template>
      <div>
        <b-form @submit="download">
          <b-form-group
            label="First Name"
            label-size="sm"
          >
            <b-form-input
              v-model="feedback.first_name"
              size="sm"
              placeholder="Please enter first name"
              required
            />
          </b-form-group>
          <b-form-group
            label="Last Name"
            label-size="sm"
          >
            <b-form-input
              v-model="feedback.last_name"
              size="sm"
              placeholder="Please enter last name"
              required
            />
          </b-form-group>
          <b-form-group
            label="Company Name （中文名）"
            label-size="sm"
          >
            <b-form-input
              id="input-2"
              v-model="feedback.company_name"
              size="sm"
              placeholder="Please write your company name in Chinese, if has Chinese name."
            />
          </b-form-group>
          <b-form-group
            label="Company Name （英文名）"
            label-size="sm"
          >
            <b-form-input
              id="input-2"
              v-model="feedback.company_name_en"
              size="sm"
              placeholder="Please write your company name in English."
              required
            />
          </b-form-group>
          <b-form-group
            label="Job Title:"
            label-size="sm"
          >
            <b-form-input
              v-model="feedback.job_title"
              size="sm"
              placeholder="Please enter job title"
              required
            />
          </b-form-group>
          <b-form-group
            label="Phone"
            label-size="sm"
          >
            <b-form-input
              v-model="feedback.phone"
              size="sm"
              placeholder="Please enter phone number"
              required
            />
          </b-form-group>
          <b-form-group
            label-size="sm"
            label="Work E-mail"
            description="Please do not provide public email like gmail, outlook. etc."
          >
            <b-form-input
              v-model="feedback.email"
              type="email"
              size="sm"
              placeholder="Please enter work email"
              required
            />
          </b-form-group>
          <div class="text-center">
            <b-button
              type="submit"
              class="submit"
              variant="warning"
              size="sm"
              :disabled="submitting"
            >
              <b-spinner
                v-if="submitting"
                small
              />
              Submit and download
            </b-button>
          </div>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { saveAs } from 'file-saver'
import SkeletonLoader from 'skeleton-loader-vue'
import { addInsightFeedback, getDetail } from '@/api/event'

export default @Component({
  components: { SkeletonLoader }
})
class NewsDetail extends Vue {
  @Prop({ required: true }) id
  loading = false
  submitting = false
  event = null
  feedback = {
    opt: 0
  }

  mounted () {
    this.getEventDetail()
  }

  @Watch('$i18n.locale')
  async getEventDetail () {
    try {
      this.loading = true
      const event = await getDetail(this.id)
      event.content = event.content.replace(/\/uploads\//g, `${process.env.VUE_APP_API_BASE_URL}/uploads/`)
      console.log(event)
      this.event = event
    } finally {
      this.loading = false
    }
  }

  showFeedback () {
    this.$refs['feedback-modal'].show()
  }

  async download (event) {
    event.preventDefault()
    try {
      this.submitting = true
      const source = this.$route.query.from || 'other'
      await addInsightFeedback({
        ...this.feedback,
        source,
        event_id: this.id
      })
      const pdf = source === 'wechat' ? this.event.pdf_cn : this.event.pdf_en
      if (pdf) {
        saveAs(pdf.url, pdf.name)
      } else {
        this.$toast('暂无文件下载')
      }
      this.$refs['feedback-modal'].hide()
    } finally {
      this.submitting = false
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  background: url("../assets/images/news/banner-detail.jpeg");
}

.title {
  margin-top: 56px;
  font-size: 1.875rem;
  color: #333333;
  text-align: center;
  font-weight: bold;
}

.time {
  font-size: 1rem;
  color: #cccccc;
  margin-top: 2rem;
  text-align: center;
}

.content {
  font-size: 1rem;
  line-height: 1.5;
  color: #333333;
  margin-top: 2rem;

  &::v-deep {
    img {
      max-width: 100%;
    }

    .image-style-align-center {
      text-align: center;
    }
  }
}

@media (max-width: 479px) {

  .title {
    text-align: center;
    margin-top: 1rem;
    font-size: 1.25rem;
    font-weight: bold;
  }

  .time {
    text-align: center;
    font-size: 12px;
    margin-top: 0.5rem;
  }

  .content {
    font-size: 0.875rem;
    margin-top: 0.7rem;
  }
}

.download {
  cursor: pointer;
}

.submit {
  color: white;
  background: #e49100;
  padding: 0.4rem 1rem;
  border-radius: 2px;
  cursor: pointer;
}
</style>
